import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "sound" ]
  connect() {
    this.soundTarget.play();
  }

  play() {
    this.soundTarget.play();
  }
}
